@import url('https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap');
@font-face {
  font-family: "Museo Sans";
  src: url("./fonts/MuseoSans-700.eot");
  src: url("./fonts/MuseoSans-700.eot?#iefix") format("embedded-opentype"),
    url("./fonts/MuseoSans-700.woff2") format("woff2"),
    url("./fonts/MuseoSans-700.woff") format("woff"),
    url("./fonts/MuseoSans-700.ttf") format("truetype"),
    url("./fonts/MuseoSans-700.svg#MuseoSans-700") format("svg");
  font-weight: 700;
  font-style: normal;
}

body {
  background-color: #fff !important;
  font-family: Montserrat;
}

header {font-size: 40px; font-weight: 500; background-color: #f0f0f0; padding:25px 0; margin-bottom: 60px;}

select {background-color: #f0f0f0; border:none; padding:0 30px; height:53px; color:rgba(0, 0, 0, 0.5); width: 100%;}

input[type='text'] {background-color: #f0f0f0; border:none; padding:30px; height:53px; color:rgba(0, 0, 0, 0.5); width: 100%;}

textarea {background-color: #f0f0f0; border:none; padding:30px; color:rgba(0, 0, 0, 0.5); width: 100%;}

input[type='range'] {
  -webkit-appearance: none;
  width: 100%;
  height: 4px;
  border-radius: 5px;
  background: #f0f0f0;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%; 
  background: #c6c6c6;
  cursor: pointer;
}

input[type='range']::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #c6c6c6;
  cursor: pointer;
}

button {
  -webkit-appearance: none;
  border:3px solid #08212b;
  background-color: transparent;
  border-radius:50px !important;
  color:#08212b;
  font-size: 16px!important;
  padding:0 30px;
  font-weight: 500;
  height:60px;
}


.preview-wrapper {
  text-align: center;
}
.preview {
  
  position: relative;
  transition: 1s;
  display: inline-block;
}

.preview .bg {
  background-color: #271d41;
  top: 0;
  left: 0;
  width: calc(100% - 45px);
  height: calc(100% - 45px);
  position: absolute;
  background-size: 100%;
  background-repeat: no-repeat;
  box-shadow: inset 400px -300px 300px 0px #271d41;
  border-radius: 0 0px 20px 0;
}

.preview .logo {
  position: absolute;
  bottom: 15px;
  right: 15px;
  max-width:300px
}

.preview .icon {
  position: absolute;
  top: 50px;
  left: 0;
  width: 180px;
  background-color: #f39520;
  height: 140px;
  border-radius: 0 20px 20px 0;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  transition: 1s;
}

.preview .icon.projectmanagement {
  background-color: #1f1635;
}
.preview .icon.advies {
  background-color: #f39420;
}
.preview .icon.interim-management {
  background-color: #25b4b1;
}
.preview .icon.professionalisering {
  background-color: #e7313c;
}

.preview .icon.projectmanagement img:nth-child(1) {
  transform: translateX(0);
  opacity: 1;
}
.preview .icon.advies img:nth-child(2) {
  transform: translateX(0);
  opacity: 1;
}
.preview .icon.interim-management img:nth-child(3) {
  transform: translateX(0);
  opacity: 1;
}
.preview .icon.professionalisering img:nth-child(4) {
  transform: translateX(0);
  opacity: 1;
}

.preview .icon img {
  right: 30px;
  display: inline-block;
  position: absolute;
  transform: translateX(-200%);
  transition: 1s;
  opacity: 0;
  height: 60%;
}
.preview .text {
  color: #fff;
  font-size: 50px;
  font-family: "Museo Sans";
  font-weight: 700;
  width: calc(100% - 300px);
  line-height: 1.2em;
  position: absolute;
  bottom: 100px;
  left: 0;
  padding-left:70px;
  text-align: left;
  white-space: pre;
  overflow: hidden;
  height:120px;
  padding-right: 75px;
  display: flex;
  align-items: flex-end;
}

.preview .text > div{}

.inputs-wrapper {
  padding: 50px 0;
  margin:auto;
}


.inputs-wrapper .input {margin-bottom: 30px;}

.inputs-wrapper .input label{
  font-weight: 500;
  display: block;
}